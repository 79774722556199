import {
    FormHelperText,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
} from "@mui/material";
import { styled, css } from "styled-components";
import theme from "styled-theming";

import Icon from "components/Icon";
import {
    styleBodyL,
    styleBodyM,
    styleBodyS,
    styleLabelM,
} from "style/components/Typography";
import { MQ } from "style/mediaQueries";

const labelColor = theme("theme", {
    undefined: "--text-on-neutral-secondary",
    lightgray: "--text-on-neutral-secondary",
    blue: "--text-on-blue-secondary",
    cyan: "--text-on-cyan-secondary",
    black: "--text-on-blue-secondary",
});

const disabledColor = theme("theme", {
    undefined: "--text-on-neutral-disabled",
    lightgray: "--text-on-neutral-disabled",
    blue: "--text-on-blue-disabled",
    cyan: "--text-on-cyan-disabled",
    black: "--text-on-blue-disabled",
});

const textColor = theme("theme", {
    undefined: "--text-on-neutral-primary",
    lightgray: "--text-on-neutral-primary",
    blue: "--text-on-blue-primary",
    cyan: "--text-on-cyan-primary",
    black: "--text-on-blue-primary",
});

const markedColor = theme("theme", {
    undefined: "--icon-on-neutral-primary",
    lightgray: "--icon-on-neutral-primary",
    blue: "--icon-on-blue-primary",
    cyan: "--icon-on-cyan-primary",
    black: "--icon-on-blue-primary",
});

const borderHover = theme("theme", {
    undefined: "--field-on-neutral-hover-border",
    lightgray: "--field-on-neutral-hover-border",
    blue: "--field-on-blue-hover-border",
    cyan: "--field-on-cyan-hover-border",
    black: "--field-on-blue-hover-border",
});

const borderColor = theme("theme", {
    undefined: "--field-on-neutral-default-border",
    lightgray: "--field-on-neutral-default-border",
    blue: "--field-on-blue-default-border",
    cyan: "--field-on-cyan-default-border",
    black: "--field-on-blue-default-border",
});

const borderDisabledColor = theme("theme", {
    undefined: "--field-on-neutral-disabled-border",
    lightgray: "--field-on-neutral-disabled-border",
    blue: "--field-on-blue-disabled-border",
    cyan: "--field-on-cyan-disabled-border",
    black: "--field-on-blue-disabled-border",
});

const borderError = theme("theme", {
    undefined: "--field-on-neutral-error-border",
    lightgray: "--field-on-neutral-error-border",
    blue: "--field-on-blue-error-border",
    cyan: "--field-on-cyan-error-border",
    black: "--field-on-blue-error-border",
});

export const Wrapper = styled(FormControl)`
    && {
        grid-column: col-start 1 / span 2;
        margin-top: 0.875rem;
        width: 100%;

        ${MQ.FROM_M} {
            margin-top: 1.3125rem;
            grid-column: ${theme("displayOption", {
                Full: "auto / span 6",
                Half: "auto / span 3",
                OneThird: "auto / span 2",
                TwoThirds: "auto / span 4",
            })};
        }

        ${MQ.FROM_XL} {
            margin-top: 1.75rem;
        }

        &:first-child {
            margin-top: 0;
        }
    }
`;

export const Label = styled(FormLabel)`
    && {
        ${styleLabelM}
        color: var(${textColor});
        margin-bottom: 1.75rem;

        &.Mui-focused {
            color: var(${textColor});
        }
    }
`;

export const CheckboxItems = styled(FormGroup)`
    && {
        row-gap: 1.3125rem;
    }
`;

export const IconWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 0.4375rem;
`;

export const CheckboxIcon = styled.div.attrs<{
    $checked: boolean;
    $error: boolean;
}>(() => ({}))`
    border: 0.0625rem solid var(${borderColor});
    border-radius: 0.25rem;
    display: block;
    height: 1.75rem;
    position: relative;
    transition: border-color 0.2s ease-in-out;
    width: 1.75rem;

    ${MQ.FROM_XL} {
        height: ${theme("size", {
            small: "1.75rem",
            medium: "2.1875rem",
        })};
        width: ${theme("size", {
            small: "1.75rem",
            medium: "2.1875rem",
        })};
    }

    &::before,
    &::after {
        background: transparent;
        border-radius: 0.1875rem;
        content: "";
        display: none;
        position: absolute;
    }

    &::before {
        background: var(${markedColor});
        transition: inset 0.1s ease-in-out;
    }

    ${(props) =>
        props.$error &&
        css`
            border-color: var(${borderError});
        `}

    ${(props) =>
        props.$checked &&
        css`
            &::before {
                display: block;
                inset: 0.4375rem;
            }
        `}
`;

export const ErrorIcon = styled(Icon)`
    color: var(${borderError});
`;

export const CheckboxLabel = styled(FormControlLabel)`
    && {
        align-items: flex-start;
        margin-left: 0;

        .MuiFormControlLabel-label {
            ${theme("size", {
                small: styleBodyM,
                medium: styleBodyL,
            })};
            color: var(${textColor});

            &.Mui-disabled {
                color: var(${disabledColor});
            }
        }

        &:hover:not(.Mui-disabled) {
            ${CheckboxIcon} {
                border-color: var(${borderHover});

                &::before {
                    border-radius: 0.125rem;
                    display: block;
                    inset: 0.6875rem;

                    ${MQ.FROM_XL} {
                        inset: ${theme("size", {
                            small: "0.6875rem",
                            medium: "0.875rem",
                        })};
                    }

                    ${(props) =>
                        props.checked &&
                        css`
                            border-radius: 0.125rem;
                            inset: 0.5625rem;

                            ${MQ.FROM_XL} {
                                border-radius: ${theme("size", {
                                    small: "0.125rem",
                                    medium: "0.1875rem",
                                })};
                                inset: 0.5625rem;
                            }
                        `}
                }
            }
        }
    }
`;

export const CheckboxControl = styled(Checkbox)`
    && {
        margin-right: 0.875rem;
        padding: 0;

        &:has(> input:focus) {
            ${CheckboxIcon} {
                border-color: var(${borderHover});
                box-shadow: 0 0 0 0.4375rem var(--field-focus);
            }
        }

        &:hover {
            background: none;
        }

        &.Mui-disabled {
            ${CheckboxIcon} {
                border-color: var(${borderDisabledColor});

                &::before {
                    background-color: var(${disabledColor});
                }
            }
        }

        &.Mui-error {
            ${CheckboxIcon} {
                border-color: var(${borderError});

                &::before {
                    background-color: var(${borderError});
                }
            }
        }
    }
`;

export const Description = styled(FormHelperText)`
    && {
        ${theme("size", {
            small: styleBodyS,
            medium: styleBodyM,
        })};
        color: var(${labelColor});
        letter-spacing: normal;
        margin-left: 0;

        &.Mui-disabled {
            color: var(${disabledColor});
        }
    }
`;

export const HelperText = styled(FormHelperText)`
    && {
        ${theme("size", {
            small: styleBodyS,
            medium: styleBodyM,
        })};
        color: var(${labelColor});
        letter-spacing: normal;
        margin: 0;
        text-transform: none;

        &.Mui-disabled {
            color: var(${disabledColor});
        }
    }
`;
