import {
    ThemeProvider as MuiThemeProvider,
    createTheme,
} from "@mui/material/styles";
import classNames from "classnames";
import React, {
    ReactElement,
    SyntheticEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ThemeProvider } from "styled-components";

import ChoiceElementProps from "components/ChoiceElement/ChoiceElementProps";
import evaluteVisibilityFromDependencies from "utils/evaluteVisibilityFromDependencies";
import generateValidationRules from "utils/generateValidationRules";

import {
    Wrapper,
    Label,
    CheckboxItems,
    CheckboxLabel,
    CheckboxControl,
    CheckboxIcon,
    HelperText,
    Description,
    ErrorIcon,
    IconWrapper,
} from "./Checkbox.styled";

const Checkbox = ({
    label,
    id,
    identifier,
    elementName,
    description,
    items,
    displayOption = "Full",
    disabled = false,
    className,
    validators,
    dependencies,
    control,
    size = "medium",
    setValue,
    onFocus,
}: ChoiceElementProps): ReactElement => {
    const formContext = useFormContext(); // retrieve those props
    const unregister = formContext?.unregister || undefined;

    const defaultValue = useMemo(
        () =>
            (items &&
                items.length > 0 &&
                items
                    .filter((item) => item.checked && item.checked === true)
                    ?.map((item) => item.value)) ||
            [],
        [items],
    );
    const [values, setValues] = useState(defaultValue);

    const classes = classNames(
        className,
        validators?.map((validator) => {
            return validator?.model?.validationCssClass || "";
        }),
    );

    const rules =
        validators && validators?.length > 0
            ? generateValidationRules(validators)
            : undefined;

    const visibleFromDependencies =
        dependencies && control
            ? evaluteVisibilityFromDependencies(dependencies, control)
            : true;

    const handleFocus = useCallback(
        (event: React.FocusEvent<HTMLButtonElement>) => {
            if (onFocus) onFocus(event);
        },
        [onFocus],
    );

    const handleChange = (
        event: SyntheticEvent<Element, Event>,
        callback: (...event: any[]) => void, // eslint-disable-line @typescript-eslint/no-explicit-any
    ) => {
        const target = event.target as HTMLInputElement;
        let newValues = typeof values === "string" ? [values] : [...values];

        if (target.checked && !newValues.includes(target.value))
            newValues.push(target.value);
        else
            newValues = newValues.filter(
                (newValue) => newValue !== target.value,
            );

        callback(newValues);
        setValues(newValues);
    };

    useEffect(() => {
        if (elementName && setValue && unregister)
            if (visibleFromDependencies) {
                setValue(elementName, defaultValue);
                setValues(defaultValue);
            } else {
                unregister(elementName);
                setValues([]);
            }
    }, [
        defaultValue,
        elementName,
        setValue,
        unregister,
        visibleFromDependencies,
    ]);

    const checkBoxIcon = (checked: boolean, error: boolean): JSX.Element => (
        <IconWrapper>
            {error && <ErrorIcon icon="error28" />}
            <CheckboxIcon $checked={checked} $error={error} />
        </IconWrapper>
    );

    return (
        <MuiThemeProvider theme={createTheme()}>
            <ThemeProvider
                theme={{
                    displayOption: displayOption,
                    size,
                }}
            >
                {visibleFromDependencies && (
                    <Wrapper>
                        {(label || description) && (
                            <Label id={id || identifier}>
                                {label}
                                {description && (
                                    <HelperText>{description}</HelperText>
                                )}
                            </Label>
                        )}

                        <Controller
                            name={elementName || ""}
                            control={control}
                            defaultValue={defaultValue}
                            rules={rules}
                            render={({
                                field: { onChange, value, ...field }, // eslint-disable-line unused-imports/no-unused-vars
                                fieldState: { error },
                            }) => {
                                return (
                                    <>
                                        <CheckboxItems
                                            aria-labelledby={id || identifier}
                                            className={classes}
                                            {...field}
                                        >
                                            {items?.map((item, index) => {
                                                return (
                                                    <div
                                                        key={`Checkbox-${index}-div`}
                                                    >
                                                        <CheckboxLabel
                                                            key={`Checkbox-${index}`}
                                                            checked={values.includes(
                                                                item.value,
                                                            )}
                                                            value={item.value}
                                                            label={
                                                                <>
                                                                    {
                                                                        item.caption
                                                                    }
                                                                    {item.description && (
                                                                        <Description
                                                                            disabled={
                                                                                item.disabled
                                                                            }
                                                                            required={
                                                                                item.required
                                                                            }
                                                                        >
                                                                            {
                                                                                item.description
                                                                            }
                                                                        </Description>
                                                                    )}
                                                                </>
                                                            }
                                                            disabled={
                                                                item.disabled ||
                                                                disabled
                                                            }
                                                            onChange={(event) =>
                                                                handleChange(
                                                                    event,
                                                                    onChange,
                                                                )
                                                            }
                                                            control={
                                                                <CheckboxControl
                                                                    disableRipple
                                                                    role="none"
                                                                    required={
                                                                        item.required
                                                                    }
                                                                    checkedIcon={checkBoxIcon(
                                                                        true,
                                                                        !!error,
                                                                    )}
                                                                    icon={checkBoxIcon(
                                                                        false,
                                                                        !!error,
                                                                    )}
                                                                    onFocus={
                                                                        handleFocus
                                                                    }
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </CheckboxItems>
                                    </>
                                );
                            }}
                        />
                    </Wrapper>
                )}
            </ThemeProvider>
        </MuiThemeProvider>
    );
};

export default React.memo(Checkbox);
